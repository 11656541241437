import service from "@/util/service.js"

// 封装一个post请求
export function postlink(url, data) {
    return new Promise((resolve, reject) => {
        service.request({
            url,
            method: "post",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
                "token": localStorage.getItem("token") || ""
            },
            data

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}